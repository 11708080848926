import React,{useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import './Denuncia.css'
import { obtenerDenuncia } from '../../Api/Api';
import { useNavigate } from "react-router-dom";
import logoW from '../../img/logoW.png'

const Denuncia = ({perfilUsuario}) => {
    console.log(perfilUsuario);
    const param = useParams();
    const [denuncia, setDenuncia] = useState([])
    
    useEffect(() => {
        obtenerDenuncia(param.id,setDenuncia);
    },[])

    //#region Enrutadores
    const idSig = denuncia.nu_siguiente_id;
    const idAnt = denuncia.nu_anterior_id;
    const idUltimo = denuncia.nu_ultimo_id;

    let navigate = useNavigate(); 
    const routeChangeVerDenunciaAnterior = (id) =>{ 
        let path = `/Denuncia/${idAnt}`; 
        navigate(path);
        window.location.reload();
    }

    const routeChangeVerDenunciaSiguiente = (id) =>{ 
        let path = `/Denuncia/${idSig}`; 
        navigate(path);
        window.location.reload();
    }
    //#endregion

    const getEstadoDenun = (estado) =>{
        if (estado === 1){
            return 'Resuelto'
        }
        else{
            return 'En proceso'
        }
    }

    const getEstadoMort = (estado) =>{
        if (estado === 1){
            return 'Fallecido'
        }
        else{
            return 'Vive'
        }
    }
    
    return (
        <>
            <div className="formContainer">
                <form className='form'>
                    {perfilUsuario !== 2 && (
                    <section id='datosPersona' className='formPersona'>
                        <div className='headerForm'>
                            <div className='columnHeaderFormL'>
                                <h1 onClick={routeChangeVerDenunciaAnterior}>⇐</h1>
                            </div>
                            <div className='columnHeaderFormC'>
                                <h1>Datos de la persona</h1>
                            </div>
                            {
                                parseInt(param.id,10) === idUltimo? <div className='columnHeaderFormL'></div>:
                                <div className='columnHeaderFormL'>
                                    <h1 onClick={routeChangeVerDenunciaSiguiente}>⇒</h1>
                                </div>
                            }
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='fname'>Nombre</label>
                                <input type="text" id="fname" name="fname" className='denunciaInput' defaultValue={denuncia.no_nombre || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'fapellido'>Apellido</label>
                                <input type="text" id="fapellido" name="fapellido" className='denunciaInput' defaultValue={denuncia.no_apellido || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='ftiponombre'>Tipo de Nombre</label>
                                <input type="text" id="ftiponombre" name="ftiponombre" className='denunciaInput' defaultValue={denuncia.no_tipo_nombre || ''}/>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='ffecha'>Fecha</label>
                                <input type="date" id="ffecha" name="ffecha" className='denunciaInput' defaultValue={denuncia.fe_fecha || ''}></input>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'ftelefono'>Telefono</label>
                                <input type="text" id="ftelefono" name="ftelefono" className='denunciaInput' defaultValue={denuncia.no_telefono || ''}></input>
                            </div>
                            <div className='column'>
                                <label htmlFor='fnacionalidad'>Nacionalidad</label>
                                <input type="text" id="fnacionalidad" name="fnacionalidad" className='denunciaInput' defaultValue={denuncia.no_nacionalidad || ''}/>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='fpais'>País</label>
                                <input type="text" id="ffecha" name="ffecha" className='denunciaInput' defaultValue={denuncia.no_pais || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'femail'>E-mail</label>
                                <input type="text" id="ftelefono" name="ftelefono" className='denunciaInput' defaultValue={denuncia.no_correo || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fgenero'>Genero</label>
                                <input type="text" id="fgenero" name="fgenero" className='denunciaInput' defaultValue={denuncia.no_genero || ''}/>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='fregion'>Región</label>
                                <input type="text" id="fregion" name="fregion" className='denunciaInput' defaultValue={denuncia.no_region || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'fsistema'>Sistema</label>
                                <input type="text" id="fsistema" name="fsistema" className='denunciaInput' defaultValue={denuncia.no_sistema || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fseguro'>Seguro</label>
                                <input type="text" id="fseguro" name="fseguro" className='denunciaInput' defaultValue={denuncia.no_tipo_seguro || ''}/>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='festablecimiento'>Establecimiento</label>
                                <input type="text" id="festablecimiento" name="festablecimiento" className='denunciaInput' defaultValue={denuncia.no_establecimiento_persona || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fedades'>Edades</label>
                                <input type="text" id="fedades" name="fedades" className='denunciaInput' defaultValue={denuncia.no_edades || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fdocumento'>Numero de documento</label>
                                <input type="text" id="fdocumento" name="fdocumento" className='denunciaInput' defaultValue={denuncia.nu_documento || ''}/>
                            </div>
                        </div>
                    </section>
                    )}

                    <section id='datosDenuncia' className='formDenuncia'>
                        <div className='headerForm'>
                            {perfilUsuario === 2 && (
                                <div className='columnHeaderFormL'>
                                <h1 onClick={routeChangeVerDenunciaAnterior}>⇐</h1>
                                </div>
                            )}
                            <div className={perfilUsuario === 2 ? 'columnHeaderFormC' : 'columnHeaderForm'}>
                                <h1>Datos de la denuncia</h1>
                            </div>

                            {perfilUsuario === 2 && (
                                parseInt(param.id, 10) === idUltimo ? (
                                    <div className='columnHeaderFormL'></div>
                                ) : (
                                    <div className='columnHeaderFormL'>
                                        <h1 onClick={routeChangeVerDenunciaSiguiente}>⇒</h1>
                                    </div>
                                )
                            )}
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor= 'fdenuncia'>Denuncia por</label>
                                <input type="text" id="fdenuncia" name="fdenuncia"  className='denunciaInput' defaultValue={denuncia.no_denuncia || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fmotivo'>Motivo de la denuncia</label>
                                <input type="text" id="fmotivo" name="fmotivo" className='denunciaInput' defaultValue={denuncia.no_motivo || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='ftipomed'>Tipo de Med/Serv.</label>
                                <input type="text" id="festablecimiento" name="festablecimiento" className='denunciaInput' defaultValue={denuncia.no_tipo || ''}/>
                            </div>
                        </div>
                        <div className='formRow'> 
                            <div className='column'>
                                <label htmlFor= 'fnombremed'>Nombre de Med/Serv</label>
                                <input type="text" id="fnombreservmed" name="fnombreservmed" className='denunciaInput' defaultValue={denuncia.no_nombre_medserv || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fregion'>Region</label>
                                <input type="text" id="fregion" name="fregion" className='denunciaInput' defaultValue={denuncia.no_region_denuncia || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'fsistema'>Sistema</label>
                                <input type="text" id="fsistema" name="fsistema" className='denunciaInput' defaultValue={denuncia.no_sistema_denuncia || ''}/>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='fdistrito'>Distrito</label>
                                <input type="text" id="fdistrito" name="fdistrito" className='denunciaInput' defaultValue={denuncia.no_distrito_denuncia || ''}></input>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'festablecimientoDenun'>Establecimiento</label>
                                <input type="text" id="festablecimientoDenun" name="festablecimientoDenun" className='denunciaInput' defaultValue={denuncia.no_establecimiento_denuncia || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fmetodoingreso'>Metodo de ingreso</label>
                                <input type="text" id="fmetodoingreso" name="fmetodoingreso" className='denunciaInput' defaultValue={denuncia.no_metodo || ''}/>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='festado'>Acción tomada</label>
                                <input type="text" id="festado" name="festado" className='denunciaInput' defaultValue={denuncia.no_estado || ''}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'festablecimientoDenun'>Estado de la Denuncia</label>
                                <input type="text" id="festadoDenun" name="festadoDenun" className='denunciaInput' value={getEstadoDenun(denuncia.nu_resuelto)}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fmortalidad'>Mortalidad </label>
                                <input type="text" id="fmortalidad" name="fmortalidad" className='denunciaInput' value={getEstadoMort(denuncia.nu_mortalidad)}/>
                            </div>
                        </div>
                        <div className='formRow'> 
                            <div className='column100'>
                                <label htmlFor='fcomentario'>Comentario</label>
                                <textarea name="fcomentario" className='denunciaInput' defaultValue={denuncia.no_comentario || ''}/>  
                                </div>
                        </div>
                        { denuncia.no_telefono === ''? <></>:
                        <div className='formRow'>
                                <div className='column'>
                                    <a href= {'https://wa.me/51'+denuncia.no_telefono} target="_blank">
                                    <img src={logoW} />
                                    </a>
                                </div>
                        </div>}
                    </section>
                    
                </form>
            </div>
        </>
    )
  }

  export default Denuncia;