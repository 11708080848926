import axios from 'axios';

//#region Base URL'S
// var BasURL= "http://192.168.18.54:9090" //prodGivar
// var BasURL = "http://localhost:9060" //test
// var BasURL= "http://192.168.0.185:9090" // test
var BasURL= "https://paolollch-001-site1.atempurl.com" //prodGivar
//#endregion

// Configuración de instancia de Axios
const axiosInstance = axios.create({
    baseURL: BasURL, // Usar la misma URL base configurada
    // otras configuraciones como headers, etc.
});

//#region Interceptor 401
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Eliminar el localStorage
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('sessionTimestamp');
            localStorage.removeItem('token');
            // Redirigir a la página de inicio de sesión
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);
//#endregion

// Función para agregar el token al header de la solicitud
const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
};

//#region Api's formulario
const TodosDatos = async (statePaises, stateRegiones) => {
    const peticion = await axiosInstance.get('/api/v1/givar/obtener-datos');
    statePaises(peticion.data.paises);
    stateRegiones(peticion.data.regiones);
}

const TodosDatosPersona = async (stateGenero, stateSeguro, stateIngreso, stateAccion, stateEdad) => {
    const peticion = await axiosInstance.get('/api/v1/givar/obtener-datos-persona-denuncia');
    stateGenero(peticion.data.genero);
    stateSeguro(peticion.data.seguro);
    stateIngreso(peticion.data.ingreso);
    stateAccion(peticion.data.accion);
    stateEdad(peticion.data.edades);
}

const SistemaSalud = async (region, stateSistema) => {
    const peticion = await axiosInstance.get(`/api/v1/givar/obtener-sistema-salud/${region}`);
    stateSistema(peticion.data);
}

const EstablecimientoSalud = async (region, sistema, stateEstablecimiento) => {
    const peticion = await axiosInstance.get(`/api/v1/givar/obtener-establecimiento-salud/${region}/${sistema}`);
    stateEstablecimiento(peticion.data);
}

const Denuncias = async (stateDenuncia) => {
    const peticion = await axiosInstance.get('/api/v1/givar/obtener-denuncias');
    stateDenuncia(peticion.data);
}

const MotivoDenuncia = async (denuncia, stateMotivo) => {
    const peticion = await axiosInstance.get(`/api/v1/givar/obtener-motivo/${denuncia}`);
    stateMotivo(peticion.data);
}

const TipoServicio = async (denuncia, motivo, stateTipo) => {
    const peticion = await axiosInstance.get(`/api/v1/givar/obtener-tipo/${denuncia}/${motivo}`);
    stateTipo(peticion.data);
}

const NombreServMed = async (denuncia, tipo, stateNombreMedServ) => {
    const peticion = await axiosInstance.get(`/api/v1/givar/obtener-nombre/${denuncia}/${tipo}`);
    stateNombreMedServ(peticion.data);
}

const GuardarDeuncia = async (denuncia) => {
    await axiosInstance.post('/api/v1/givarDenuncias/guardar-denuncia', denuncia, getAuthHeaders());
}

const ActualizarDenunciaApi = async (denuncia) => {
    await axiosInstance.post('/api/v1/givarDenuncias/actualizar-denuncia', denuncia, getAuthHeaders());
}

const EliminarDenunciaApi = async (denuncia) => {
    await axiosInstance.post(`/api/v1/givarDenuncias/eliminar-denuncia/${denuncia}`, getAuthHeaders());
}

//#endregion

//#region Api's bandeja
const DenunciasBandeja = async (stateDenunciaBandeja, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/givarDenuncias/obtener-datos/${fe_inicio}/${fe_fin}`, getAuthHeaders());
    stateDenunciaBandeja(peticion.data);
}

const obtenerDenuncia = async (id, stateDenuncia) => {
    const peticion = await axiosInstance.get(`/api/v1/givarDenuncias/obtener-denuncia/${id}`, getAuthHeaders());
    stateDenuncia(peticion.data);
}

//#endregion

//#region Api's graficos
const DenunciasRegion = async (state, region, inicio, fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-regiones/${region}/${inicio}/${fin}`);
    state(peticion.data);
}

const DenunciasGenero = async (state, inicio, fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-generos/${inicio}/${fin}`);
    state(peticion.data);
}

const DenunciasNacionalidad = async (state, inicio, fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-nacionalidad/${inicio}/${fin}`);
    state(peticion.data);
}

const DenunciasEstablecimiento = async (state, region, inicio, fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-establecimientos/${region}/${inicio}/${fin}`);
    state(peticion.data);
}

const DenunciasAnio = async (state, anio) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denuncia-anio/${anio}`);
    state(peticion.data);
}

const DenunciasIdentificadas = async (state, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-identificados/${fe_inicio}/${fe_fin}`);
    state(peticion.data);
}

const DenunciasIngreso = async (state, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denuncia-ingreso/${fe_inicio}/${fe_fin}`);
    state(peticion.data);
}

const DenunciasDenunciaPor = async (state, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denunciapo/${fe_inicio}/${fe_fin}`);
    state(peticion.data);
}

const DenunciasDenunciaMedicamentos = async (state, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denuncia-medicamentos/${fe_inicio}/${fe_fin}`);
    state(peticion.data);
}

const DenunciasDenunciaSistema = async (state, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denuncia-sistema/${fe_inicio}/${fe_fin}`);
    state(peticion.data);
}

const DenunciasMotivo = async (state, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denuncia-motivo/${fe_inicio}/${fe_fin}`);
    state(peticion.data);
}

const DenunciasFlujo = async (state, sistema, inicio, fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denuncia-flujo/${sistema}/${inicio}/${fin}`);
    state(peticion.data);
}

const DenunciasDesabastecimiento = async (state, inicio, fin) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-denuncia-desabastecimiento/${inicio}/${fin}`);
    state(peticion.data);
}

const DenunciasSistemaFlujo = async (state) => {
    const peticion = await axiosInstance.get(`/api/v1/graficos/obtener-datos-sistema-flujo`);
    state(peticion.data);
}

const ActualizarMapaGoogle = async (anio) => {
    await axiosInstance.post(`/api/v1/graficos/actualizar-mapa/${anio}`);
}
//#endregion

//#region Api's CSV
const DenunciasCSV = async (state, fe_inicio, fe_fin) => {
    const peticion = await axiosInstance.get(`/api/v1/givarDenuncias/obtener-denuncias-csv/${fe_inicio}/${fe_fin}`, getAuthHeaders());
    state(peticion.data);
}
//#endregion

//#region Login
const LoginUsuario = async (usuario, clave) => {
    try {
        const response = await axios.post(BasURL + '/api/v1/seguridad/login', {
            usuario,
            clave
        });
        return response.data; // Devolver los datos de respuesta (posiblemente incluye token u otros datos de usuario)
    } catch (error) {
        throw error; // Manejar el error en el contexto donde se llame esta función
    }
}

const ValidarPerfil = async () => {
    const tokenURL = localStorage.getItem('token') && localStorage.getItem('token') !== '' ? localStorage.getItem('token') : 'Usuario';
    const response  = await axiosInstance.post(`/api/v1/seguridad/perfil/${tokenURL}`);
    return response.data;
}

//#endregion

export {
    TodosDatos,
    TodosDatosPersona,
    SistemaSalud,
    Denuncias,
    MotivoDenuncia,
    TipoServicio,
    NombreServMed,
    DenunciasBandeja,
    obtenerDenuncia,
    GuardarDeuncia,
    ActualizarDenunciaApi,
    DenunciasRegion,
    DenunciasGenero,
    DenunciasNacionalidad,
    DenunciasEstablecimiento,
    EstablecimientoSalud,
    DenunciasAnio,
    DenunciasIdentificadas,
    DenunciasIngreso,
    DenunciasDenunciaPor,
    DenunciasDenunciaMedicamentos,
    DenunciasDenunciaSistema,
    DenunciasCSV,
    DenunciasMotivo,
    DenunciasFlujo,
    DenunciasSistemaFlujo,
    EliminarDenunciaApi,
    ActualizarMapaGoogle,
    LoginUsuario,
    ValidarPerfil,
    DenunciasDesabastecimiento
}
