import React,{useState} from 'react';
import MaterialTable  from '@material-table/core';
import './Bandeja.css'
import { DenunciasBandeja, DenunciasCSV, EliminarDenunciaApi, ActualizarMapaGoogle} from '../../Api/Api';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from 'react-csv';
import AlertModal from '../../components/Modal/AlertModal';
// import { type } from "@testing-library/user-event/dist/type";
// import { DateRange } from "@mui/icons-material";

const Bandeja  = ({ perfilUsuario }) => {
    
    const [fechaInicio, setFechaInicio] = useState(new Date(new Date().getFullYear(), 0).toISOString().split('T')[0]); // Primera fecha del año actual
    const [fechaFin, setFechaFin] = useState(new Date(new Date().getFullYear(), 11, 31).toISOString().split('T')[0]); // Última fecha del año actual    
    const[data,setData] = useState([]);
    const[dataCSV,setDataCSV] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalGoogleOpen, setModalGoogleOpen] = useState(false);
    const[idSelected, setIdSelected] = useState(0);

    //#region  Enrutadores
    let navigate = useNavigate(); 
    const routeChangeVerDenuncia = (id) =>{ 
        let path = `/Denuncia/${id}`; 
        navigate(path);
    }

    const routeChangeActualizarDenuncia = (id) => {
        let path = `/ActualizarDenuncia/${id}`;
        navigate(path);
    }

    console.log(perfilUsuario)
    //#endregion

    //#region Columnas de la tabla
    const columns=[
        {
            title:'Id',
            field: 'id',
            defaultSort : 'desc'
        },
        {
            title:'Nombre',
            field: 'no_nombre'
        },
        {
            title:'Pais',
            field: 'no_pais'
        },
        {
            title:'Nacionalidad',
            field: 'no_nacionalidad', 
        },
        {
            title:'Fecha',
            field: 'fe_fecha', 
            type: 'date',
            dateSetting: { locale: 'es'},
            filtering: false
        }
    ]
    //#endregion

    //#region UseEffect
    useEffect(()=>{
        if( fechaInicio !== '' && fechaFin !== ''){
            DenunciasBandeja(setData,fechaInicio,fechaFin);
        }
    },[fechaInicio,fechaFin])

    const obtenerCSV=()=>{
        if( fechaInicio !== '' && fechaFin !== ''){
            DenunciasCSV(setDataCSV,fechaInicio,fechaFin);
            console.log(dataCSV)
        }
    }

    const getValueFecha =(option, state) => {
        state(option.target.value);
    }

    const getIdModal = (id) => {
        setModalOpen(true);
        setIdSelected(id);
    }

    const ActualizarMapa = () =>{
        const fechaActual = new Date();
        const anoActual = fechaActual.getFullYear();
        ActualizarMapaGoogle(parseInt(anoActual))
        setModalGoogleOpen(true)
        // console.log(añoActual)
    }

    //#region CSV

    const headers = [
        {
            label: "Id", key: "id"
        },
        {
            label: "Nombre", key: "no_nombre"
        },
        {
            label: "Apellido", key: "no_apellido"
        },
        {
            label: "Tipo_Nombre", key: "no_tipo_nombre"
        },
        {
            label: "Fecha_creacion", key: "fe_fecha"
        },
        {
            label: "Nacionalidad", key: "no_nacionalidad"
        },
        {
            label: "Genero", key: "no_genero"
        },
        {
            label: "Pais", key: "no_pais"
        },
        {
            label: "Telefono", key: "no_telefono"
        },
        {
            label: "Correo", key: "no_correo"
        },
        {
            label: "Region", key: "no_region"
        },
        {
            label: "Sistema", key: "no_sistema"
        },
        {
            label: "Sistema", key: "no_sistema"
        },
    ]

    const csvLink = {
        filename: 'Reporte.csv',
        headers: headers,
        data: dataCSV,
        className: 'hidden',
        target : '_blank'
    }

    //#endregion

    //#region Modal
    const handleConfirm = () => {
        // Lógica para el botón Confirmar
        EliminarDenunciaApi(idSelected)
        setModalOpen(false);
        window.location.reload();
      };
    
    const handleCancel = () => {
        // Lógica para el botón Cancelar
        setModalOpen(false);
      };

    const handleCancelGoogle = () => {
        // Lógica para el botón Cancelar
        setModalGoogleOpen(false);
    };

    //#endregion
    return (
        <>
        <div className="tableContainer">
            <link rel="stylesheet"href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
            <center>
                <div className="buttonBandeja">
                    <label>Fecha inicio:</label>
                    <input className='inputDate' type='date' value={fechaInicio} onChange={(e) => getValueFecha(e,setFechaInicio)}/>
                    <label>Fecha fin:</label>
                    <input className='inputDate' type='date' value={fechaFin} onChange={(e) => getValueFecha(e,setFechaFin)}/>
                    <div className='export-int'>
                        <CSVLink {...csvLink}> 
                            <button onClick={obtenerCSV}> Exportar CSV </button>
                        </CSVLink>
                        <button onClick={ActualizarMapa}>Actualizar Mapa</button>
                    </div>
                    
                </div>
            </center>
            <MaterialTable
                columns={columns}
                title = {"Bandeja de denuncias"}
                actions={[
                    {
                      icon: 'search',
                      tooltip: 'Ver denuncia',
                      onClick: (event, rowData) => { routeChangeVerDenuncia(rowData.id) }
                    },
                    ...(perfilUsuario === 1 ? [
                      {
                        icon: 'edit',
                        tooltip: 'Editar denuncia',
                        onClick: (event, rowData) => { routeChangeActualizarDenuncia(rowData.id) }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Eliminar denuncia',
                        onClick: (event, rowData) => { getIdModal(rowData.id) }
                      }
                    ] : (perfilUsuario === 3 ? [
                      {
                        icon: 'edit',
                        tooltip: 'Editar denuncia',
                        onClick: (event, rowData) => { routeChangeActualizarDenuncia(rowData.id) }
                      }
                    ] : []))
                  ]}
                data = {data}
                options ={{
                    actionsColumnIndex: -1,
                    pageSize:10,
                    filtering: true
                    // headerStyle:{backgroundColor:'red'}
                }}
                localization={{
                    filterRow: {
                        filterTooltip: 'Filtrar'
                    }
                }}
            />
        </div>
        <AlertModal
        isOpen={modalOpen}
        title="¿Desea Eliminar?"
        message=""
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        />
        <AlertModal
        isOpen={modalGoogleOpen}
        title="Actualizado Correctamente"
        message=""
        onConfirm={handleCancelGoogle}
        onCancel= {null}
        />            
        </>
    );
}

export default Bandeja 