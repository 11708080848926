import React, { useState, useEffect } from 'react';
import "./Consultas.css"
import GraphRegiones from "./graphs/graphRegiones"
import GraphGenero from "./graphs/graphGenero"
import GraphNacionalidad from "./graphs/graphNacionalidad"
import GraphBarEstablecimientos from "./graphs/graphBarEstablecimientos"
import GraphLineDenuncias from "./graphs/graphLineDenuncias"
import GraphIdentificados from "./graphs/graphIdentificados"
import GraphIngreso from "./graphs/graphIngreso";
import GraphDenunciaPor from "./graphs/graphDenunciaPor"
import GraphBarMedicamentos from "./graphs/graphBarMedicamentos"
import GraphDenunciaSistema from "./graphs/graphSistema"
import GraphMotivos from "./graphs/graphMotivos"
import GraphBarDesabastecimiento from "./graphs/graphDesabastecimiento"
import { ValidarPerfil } from "../../Api/Api";

function Consultas(){
    
    const [perfilUsuario, setPerfilUsuario] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const validarUsuario = async () => {
            setLoading(true); // Activar el estado de carga

            if (!perfilUsuario) {
                try {
                    const response = await ValidarPerfil(); // Llama a la API para validar el perfil del usuario
                    setPerfilUsuario(response.perfil_usuario);
                } catch (error) {
                    console.error("Error al validar el perfil del usuario", error);
                }
            }

            setLoading(false); // Desactivar el estado de carga cuando se completa la validación
        };

        validarUsuario();
    }, [perfilUsuario, setPerfilUsuario]);
    
    console.log(perfilUsuario)

    if (loading) {
        return (
            <div className="loading-overlay">
                <div className="loading">Cargando...</div>
            </div>
        );
    }

    return(
        <>
            <div className="containerConsultas">
                <div className='formRowBar'>
                    <GraphLineDenuncias perfilUsuario = {perfilUsuario}/>
                </div>
                <h1>Consultas</h1>
                <div className='formRow'>
                    {(perfilUsuario !== 0) && (
                        <div className='column'>
                            <GraphRegiones perfilUsuario = {perfilUsuario}/>
                        </div>
                    )}
                    <div className="column">
                        <GraphGenero perfilUsuario = {perfilUsuario}/>
                    </div>
                    <div className="column">
                        <GraphNacionalidad perfilUsuario = {perfilUsuario}/>
                    </div>
                </div>
                <div className='formRow'>
                    <div className='column'>
                        <GraphIdentificados perfilUsuario = {perfilUsuario}/>
                    </div>
                    <div className='column'>
                        <GraphIngreso perfilUsuario = {perfilUsuario}/>
                    </div>
                    <div className='column'>
                        <GraphDenunciaSistema perfilUsuario = {perfilUsuario}/>
                    </div>
                </div>
                <div className='formRow'>
                    <GraphDenunciaPor perfilUsuario = {perfilUsuario}/>
                </div>
                <div className='formRow'>
                    <GraphMotivos perfilUsuario = {perfilUsuario}/>
                </div>
                <div className='formRow'>
                    <GraphBarDesabastecimiento perfilUsuario = {perfilUsuario}/>
                </div>
                <div className='formRowBar'>
                    <GraphBarMedicamentos perfilUsuario = {perfilUsuario}/>
                </div>
                <div className='formRowBar'>
                    <GraphBarEstablecimientos perfilUsuario = {perfilUsuario}/>
                </div>
            </div>
        </>
    )
}

export default Consultas
