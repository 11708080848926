import React, {useEffect, useState} from "react";
import "../Consultas.css"
import { TodosDatos } from "../../../Api/Api";
import { DenunciasRegion } from "../../../Api/Api";
import CanvasJSReact from '../../../canvasjs.react';

function GraphRegiones({perfilUsuario}){

    const [paises, setPaises] = useState([]);
    const [region, setRegion] = useState([]);
    const [regionSelected, setRegionSelected] = useState('TODOS');
    const [dataGrafico, setDataGragfico] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(new Date(new Date().getFullYear(), 0).toISOString().split('T')[0]); // Primera fecha del año actual
    const [fechaFin, setFechaFin] = useState(new Date(new Date().getFullYear(), 11, 31).toISOString().split('T')[0]); // Última fecha del año actual    

    
    useEffect(()=>{
        TodosDatos(setPaises,setRegion);
    },[]);

    useEffect(()=>{
        if(regionSelected !== '' && fechaInicio !== '' && fechaFin !== ''){
            DenunciasRegion(setDataGragfico,regionSelected,fechaInicio,fechaFin)
        }
    },[regionSelected,fechaInicio,fechaFin])

    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

		const options = {
			animationEnabled: true,
			title: {
				text: "Denuncias por Región"
			},
			subtitles: [{
				text: "Denuncias",
				verticalAlign: "center",
				fontSize: 24,
				dockInsidePlotArea: true
			}],
			data: [{
				type: "doughnut",
				showInLegend: true,
				indexLabel: "{name}: {y}",
				yValueFormatString: "#,###",
				dataPoints: [
					{ name: "Resuelto", y: dataGrafico.map((i)=>i.resuelto) },
					{ name: "No resuelto", y: dataGrafico.map((i)=>i.no_resuelto) }
				]
			}]
        }

    const getValue =(option, state) => {
        state(option.target.value);
    }

    const getValueFecha =(option, state) => {
        state(option.target.value);
    }

    return(
        <>
            <div className="regionesDenunciasContainer">
                <center>
                <select onChange={(e)=> getValue(e,setRegionSelected)}>
                    <option value="" hidden>--ELEGIR REGION--</option>
                    <option value="TODOS">--TODAS LAS REGIONES--</option>
                    {region.map((pais,i) => (
                        <option key={i} value={pais} >{pais}</option>
                    ))}
                </select>
                <div className="buttonFe">
                    <label>Fecha inicio</label>
                    <input 
                        type='date' 
                        value={fechaInicio} 
                        onChange={(e) => getValueFecha(e,setFechaInicio)}
                        disabled={perfilUsuario === 0}
                    />
                    <label>Fecha fin</label>
                    <input 
                        type='date' 
                        value={fechaFin} 
                        onChange={(e) => getValueFecha(e,setFechaFin)}
                        disabled={perfilUsuario === 0}    
                    />
                </div>
                <CanvasJSChart options = {options} />
                </center>
            </div>
        </>
    )
}


export default GraphRegiones