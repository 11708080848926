import React,{useState, useEffect} from "react";
import {Chart as ChartJS,CategoryScale,BarElement,LinearScale,Title,Tooltip,Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DenunciasSistemaFlujo, DenunciasFlujo} from "../../../Api/Api";
import '../Consultas.css'


const GraphBarFlujos = () => {

    const [sistema, setSistema] = useState([]);
    const [sistemaSelected, setSistemaSelected] = useState('MINSA');
    const [dataChart, setDataChart] = useState([]);
    const [fechaInicio, setFechaInicio] = useState('2022-01-01');
    const [fechaFin, setFechaFin] = useState('2022-12-31');

    useEffect(()=>{
        DenunciasSistemaFlujo(setSistema);
    },[])

    useEffect(()=>{
        DenunciasFlujo(setDataChart,sistemaSelected,fechaInicio,fechaFin);
    },[sistemaSelected,fechaInicio,fechaFin])

    //#region barData
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
      
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels = dataChart.map(x => x.no_mes)
      
    const data = {
        labels,
        datasets: [
          {
            label: 'Denuncias',
            data: dataChart.map(x => x.denuncias),
            backgroundColor: 'rgba(62,64,149,255)',
          },
          {
            label: 'Derivados',
            data: dataChart.map(x => x.derivados),
            backgroundColor: 'rgba(244, 208, 63)',
          },
          {
            label: 'No resueltas',
            data: dataChart.map(x => x.no_resueltos),
            backgroundColor: 'rgba(237,47,89,255)',
          },
          {
            label: 'Resueltas',
            data: dataChart.map(x => x.resueltos),
            backgroundColor: 'rgba(46, 204, 113 ,255)',
          },
        ],
      };
//#endregion

    const getValueFun = (e, state)=>{
    state(e.target.value);
    // console.log(e.target.value)
    // console.log(option.target.value);
    }

    const getValueFecha =(option, state) => {
        state(option.target.value);
    }


return(
        <>
        <div className="regionesDenunciasContainer">
        <h1>Flujo de denuncias</h1>
        <center>
            <select onChange={(e)=> getValueFun(e,setSistemaSelected)}>
                <option value="" hidden>MINSA</option>
                {sistema.map((sistema,i) => (
                    <option key={i} value={sistema} >{sistema}</option>
                ))}
            </select>
            <div className="buttonGraph">
                <label>Fecha inicio</label>
                <input className='inputDate' type='date' value= {fechaInicio}onChange={(e) => getValueFecha(e,setFechaInicio)}/>
                <label>Fecha fin</label>
                <input className='inputDate' type='date' value= {fechaFin} onChange={(e) => getValueFecha(e,setFechaFin)}/>
            </div>
        </center>
        <div className="barContainer">
            <Bar options={options} data={data} />
        </div>
        </div>
        </>
    )
}

export default GraphBarFlujos