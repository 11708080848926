import React, { useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./Navbar.css";
import logo from "./logo.png";
import { ValidarPerfil } from "../../Api/Api";

const Navbar = ({ onLogout, perfilUsuario, setPerfilUsuario }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validarUsuario = async () => {
      if (!perfilUsuario) {
        try {
          const response = await ValidarPerfil(); // Llama a la API para validar el perfil del usuario
          if (response.token && response.perfil_usuario) {
            setPerfilUsuario(response.perfil_usuario);
          }
        } catch (error) {
          console.error("Error al validar el perfil del usuario", error);
        }
      }
      setLoading(false);
    };

    validarUsuario();
  }, [perfilUsuario, setPerfilUsuario]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <nav className="nav">
      <Link to="/Home" className="site-title">
        <img src={logo} alt="Logo" className="img" />
      </Link>
      <ul>
        {(perfilUsuario === 1 || perfilUsuario === 3) && (
          <>
            <CustomLink to="/Formulario">Formulario</CustomLink>
            <CustomLink to="/Bandeja">Bandeja</CustomLink>
            <CustomLink to="/Consultas">Consultas</CustomLink>
          </>
        )}
        {perfilUsuario === 2 && (
          <>
            <CustomLink to="/Bandeja">Bandeja</CustomLink>
            <CustomLink to="/Consultas">Consultas</CustomLink>
          </>
        )}
      </ul>
      <button onClick={onLogout}>Logout</button>
    </nav>
  );
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default Navbar;
