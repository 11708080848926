import React, {useEffect,useState} from "react";
import CanvasJSReact from '../../../canvasjs.react';
import {DenunciasGenero} from "../../../Api/Api";

const GraphGenero = ({perfilUsuario}) => {

    const [fechaInicio, setFechaInicio] = useState(new Date(new Date().getFullYear(), 0).toISOString().split('T')[0]); // Primera fecha del año actual
    const [fechaFin, setFechaFin] = useState(new Date(new Date().getFullYear(), 11, 31).toISOString().split('T')[0]); // Última fecha del año actual    
    const [data, setData] = useState([]);
    const [dataChart, setDataChart] = useState([]);
    var dps = [];


    useEffect(()=>{
        if(fechaInicio !== '' && fechaFin !== ''){
            DenunciasGenero(setData,fechaInicio,fechaFin)
        }
    },[fechaInicio,fechaFin])

    useEffect(()=>{
        data.map((item)=> dps.push({name: item.no_nombre, y: item.denuncias}))
        setDataChart(dps)
    },[data])


    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

		const options = {
			animationEnabled: true,
			title: {
				text: "Denuncias por Genero"
			},
			subtitles: [{
				text: "Denuncias",
				verticalAlign: "center",
				fontSize: 24,
				dockInsidePlotArea: true
			}],
			data: [{
				type: "doughnut",
				showInLegend: true,
				indexLabel: "{name}: {y}",
				yValueFormatString: "#,###", 
				dataPoints: dataChart
			}]
        }

    const getValueFecha =(option, state) => {
        state(option.target.value);
    }

    return(
        <>
        <div className="regionesDenunciasContainer">
                <center>
                <div className="buttonFe">
                    <select className="selectHidde" hidden ></select>
                    <label>Fecha inicio</label>
                    <input 
                        type='date' 
                        value={fechaInicio} 
                        onChange={(e) => getValueFecha(e,setFechaInicio)}
                        disabled={perfilUsuario === 0}    
                    />
                    <label>Fecha fin</label>
                    <input 
                        type='date' 
                        value={fechaFin} 
                        onChange={(e) => getValueFecha(e,setFechaFin)}
                        disabled={perfilUsuario === 0}
                    />
                    <CanvasJSChart options = {options}/>
                </div>
                </center>
            </div>
        </>
    )
}

export default GraphGenero