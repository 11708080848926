import React,{useState, useEffect} from "react";
import {Chart as ChartJS,CategoryScale,BarElement,LinearScale,Title,Tooltip,Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {DenunciasDenunciaMedicamentos} from "../../../Api/Api";
import '../Consultas.css'


const GraphBarMedicamentos = ({perfilUsuario}) => {

    const [regionSelected, setRegionSelected] = useState('LIMA');
    const [dataChart, setDataChart] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(new Date(new Date().getFullYear(), 0).toISOString().split('T')[0]); // Primera fecha del año actual
    const [fechaFin, setFechaFin] = useState(new Date(new Date().getFullYear(), 11, 31).toISOString().split('T')[0]); // Última fecha del año actual 

    useEffect(()=>{
        DenunciasDenunciaMedicamentos(setDataChart,fechaInicio,fechaFin);
    },[fechaInicio,fechaFin])

    //#region barData
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
      
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels = dataChart.map(x => x.no_nombre)
      
    const data = {
        labels,
        datasets: [
          {
            label: 'Denuncias',
            data: dataChart.map(x => x.denuncias),
            backgroundColor: 'rgba(62,64,149,255)',
          },
        ],
      };
//#endregion

    const getValueFun = (e, state)=>{
    state(e.target.value);
    // console.log(e.target.value)
    // console.log(option.target.value);
    }

    const getValueFecha =(option, state) => {
        state(option.target.value);
    }


return(
        <>
        <div className="regionesDenunciasContainer">
        <h1>Denuncias por medicamentos</h1>
        <center>
            <div className="buttonGraph">
                    <label>Fecha inicio</label>
                    <input 
                        type='date' 
                        value={fechaInicio} 
                        onChange={(e) => getValueFecha(e,setFechaInicio)}
                        disabled={perfilUsuario === 0}    
                    />
                    <label>Fecha fin</label>
                    <input 
                        type='date' 
                        value={fechaFin} 
                        onChange={(e) => getValueFecha(e,setFechaFin)}
                        disabled={perfilUsuario === 0}
                    />
            </div>
        </center>
        <div className="barContainer">
            <Bar options={options} data={data} />
        </div>
        </div>
        </>
    )
}

export default GraphBarMedicamentos