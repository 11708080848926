import React, { useState } from 'react';
import './Login.css';
import logo from "./logo.png";
import AlertModal from '../../components/Modal/AlertModal';
import { LoginUsuario } from '../../Api/Api';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js'; // Importa la biblioteca CryptoJS

const secretKey = 'givarDenuncias425'; // Clave secreta para cifrado

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [modalLoginOpen, setModalLoginOpen] = useState(false);
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        const response = await LoginUsuario(username, password);

        if (response.token) { // Verifica que el token no esté vacío
            const encryptedProfile = CryptoJS.AES.encrypt(JSON.stringify(response.perfil_usuario), secretKey).toString(); // Cifra el perfil de usuario
            localStorage.setItem('profile', encryptedProfile); // Guarda el perfil cifrado en localStorage
            localStorage.setItem('token', response.token); // Guarda el token en localStorage
            onLogin(response.perfil_usuario);
            navigate('/Home');
        } else {
            throw new Error('Credenciales inválidas');
        }
    };

    const handleCancelLogin = () => {
        setModalLoginOpen(false);
    };

    return (
        <>
            <div className="login-container">
                <div className="login-box">
                    <img src={logo} alt="Logo" className="img" />
                    <form className="login-form" onSubmit={handleLoginSubmit}>
                        <label htmlFor="username">Usuario</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <label htmlFor="password">Contraseña</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button type="submit">Ingresar</button>
                    </form>
                </div>
            </div>
            <AlertModal
                isOpen={modalLoginOpen}
                title="Error al iniciar sesión"
                message={loginError}
                onConfirm={handleCancelLogin}
                onCancel={null}
            />
        </>
    );
}

export default Login;