import React,{useState, useEffect} from "react";
import {Chart as ChartJS,CategoryScale,BarElement,LinearScale,Title,Tooltip,Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { TodosDatos, DenunciasEstablecimiento} from "../../../Api/Api";
import '../Consultas.css'


const GraphBarEstablecimientos = ({perfilUsuario}) => {

    const [region, setRegion] = useState([]);
    const [paises, setPaises] = useState([]);
    const [regionSelected, setRegionSelected] = useState('LIMA');
    const [dataChart, setDataChart] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(new Date(new Date().getFullYear(), 0).toISOString().split('T')[0]); // Primera fecha del año actual
    const [fechaFin, setFechaFin] = useState(new Date(new Date().getFullYear(), 11, 31).toISOString().split('T')[0]); // Última fecha del año actual 

    useEffect(()=>{
        TodosDatos(setPaises,setRegion);
    },[])

    useEffect(()=>{
        DenunciasEstablecimiento(setDataChart,regionSelected,fechaInicio,fechaFin);
    },[regionSelected,fechaInicio,fechaFin])

    //#region barData
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
      
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels = dataChart.map(x => x.no_establecimiento_persona)
      
    const data = {
        labels,
        datasets: [
          {
            label: 'Resueltas',
            data: dataChart.map(x => x.resuelto),
            backgroundColor: 'rgba(62,64,149,255)',
          },
          {
            label: 'No resueltas',
            data: dataChart.map(x => x.no_resuelto),
            backgroundColor: 'rgba(237,47,89,255)',
          },
        ],
      };
//#endregion

    const getValueFun = (e, state)=>{
    state(e.target.value);
    // console.log(e.target.value)
    // console.log(option.target.value);
    }

    const getValueFecha =(option, state) => {
        state(option.target.value);
    }


return(
        <>
        <div className="regionesDenunciasContainer">
        <h1>Denuncias por establecimientos</h1>
        <center>
            <select onChange={(e)=> getValueFun(e,setRegionSelected)}>
                <option value="" hidden>--ELEGIR REGION--</option>
                <option value="TODOS">--TODAS LAS REGIONES--</option>
                {region.map((pais,i) => (
                    <option key={i} value={pais} >{pais}</option>
                ))}
            </select>
            <div className="buttonGraph">
                    <label>Fecha inicio</label>
                    <input 
                        type='date' 
                        value={fechaInicio} 
                        onChange={(e) => getValueFecha(e,setFechaInicio)}
                        disabled={perfilUsuario === 0}    
                    />
                    <label>Fecha fin</label>
                    <input 
                        type='date' 
                        value={fechaFin} 
                        onChange={(e) => getValueFecha(e,setFechaFin)}
                        disabled={perfilUsuario === 0}
                    />
            </div>
        </center>
        <div className="barContainer">
            <Bar options={options} data={data} />
        </div>
        </div>
        </>
    )
}

export default GraphBarEstablecimientos