import './Formulario.css';
import React,{useState, useEffect} from 'react';
import { TodosDatos, TodosDatosPersona,SistemaSalud, Denuncias, MotivoDenuncia, TipoServicio, NombreServMed, GuardarDeuncia, EstablecimientoSalud} from '../../Api/Api';
import AlertModal from '../../components/Modal/AlertModal';

const Formulario  = () => {
    
    //#region Variables locales
    //#region Variables Api's
    const [paises, setPaises] = useState([]);
    const [region, setRegion] = useState([]);
    const [sistema, setSistema] = useState([]);
    const [genero, setGenero] = useState([]);
    const [seguro, setSeguro] = useState([]);
    const [ingreso, setIngreso] = useState([]);
    const [accion, setAccion] = useState([]);
    const [sistemaDenu, setSistemaDenu] = useState([]);
    const [establecimiento, setEstablecimiento] = useState([]);
    const [motivo, setMotivo] = useState([]);
    const [denuncia, setDenuncia] = useState([])
    const [tipo, setTipo] = useState([])
    const [nombreServMed, setNombreServMed] = useState([])
    const [establecimientoDenuncia, setEstablecimientoDenuncia] = useState([]);
    const [edad, setEdad] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    //#endregion
    //#region Variables Selected
    const [regionPersonaSelected, setRegionPersonaSelected] = useState('');
    const [denunciaSelected, setDenunciaSelected] = useState('');
    const [motivoSelected, setMotivoSelected] = useState('');
    const [tipoSelected, setTipoSelected] = useState('');
    const [regionDenunciaSelected, setRegionDenunciaSelected] = useState('');
    const [fechaSelected, setFechaSelected] = useState('');
    const [sistemaPersonaSelected, setSistemaPersonaSelected] = useState('');
    const [sistemaDenuSelected, setSistemaDenuSelected] = useState('');
    const [esPeruano, setEsPeruano] = useState(false);
    //#endregion
    //#endregion

    //#region Data
    const[data,setData] = useState({
        no_nombre: '',
        no_apellido:'' ,
        no_tipo_nombre: '',
        fe_fecha: '',
        no_nacionalidad: '',
        no_genero: '',
        no_pais: '',
        no_telefono: '',
        no_correo:'' ,
        no_region: '',
        no_sistema: '',
        no_tipo_seguro: '',
        no_establecimiento_persona: '',
        no_denuncia: '',
        no_motivo: '',
        no_tipo: '',
        no_nombre_medserv: '',
        no_region_denuncia: '',
        no_sistema_denuncia:'' ,
        no_distrito_denuncia: '',
        no_establecimiento_denuncia: '',
        no_metodo: '',
        no_estado: '',
        nu_resuelto: 0,
        nu_mortalidad: 0,
        no_comentario: '',
        no_edades: '',
        nu_documento: ''
    });

    const sendData = ()=>{
        if(fechaSelected === ''){
            alert('Seleccione una fecha')
        }
        if(data.no_metodo === ''){
            alert('Seleccione un metodo')
        }
        if(data.no_denuncia === ''){
            alert('Seleccione un tipo de denuncia')
        }
        if(data.no_estado === ''){
            alert('Seleccione una acción')
        }
        if(data.no_region_denuncia === ''){
            alert('Seleccione la región de la denuncia')
        }
        else{
            GuardarDeuncia(data)
            setModalOpen(true);
        }
    }

    const handleCancel = () => {
        // Lógica para el botón Cancelar
        setModalOpen(false);
      };

    const reload = () => {
        window.location.reload()
    } 

    //#endregion

    //#region Formulario Global Paises - Region
    useEffect(()=>{
        TodosDatos(setPaises,setRegion);
        Denuncias(setDenuncia);
        TodosDatosPersona(setGenero,setSeguro,setIngreso,setAccion,setEdad);
    },[])
    //#endregion

    //#region Funciones Formulario Persona
    useEffect(()=>{
        if(regionPersonaSelected !== ''){
            SistemaSalud(regionPersonaSelected,setSistema);
        }
    },[regionPersonaSelected])

    useEffect(()=>{
        if(sistemaPersonaSelected !== ''){
            EstablecimientoSalud(regionPersonaSelected,sistemaPersonaSelected,setEstablecimiento);
        }
    },[regionPersonaSelected,sistemaPersonaSelected])
    //#endregion

    //#region Funciones Formulario Denuncia
    useEffect(()=>{
        if(regionDenunciaSelected !== ''){
            SistemaSalud(regionDenunciaSelected,setSistemaDenu);
        }
    },[regionDenunciaSelected])

    useEffect(()=>{
        if(denunciaSelected !== ''){
            MotivoDenuncia(denunciaSelected,setMotivo);
        }
    },[denunciaSelected])

    useEffect(()=>{
        if(motivoSelected !== '' && denunciaSelected !== ''){
            TipoServicio(denunciaSelected,motivoSelected,setTipo);
        }
    },[denunciaSelected,motivoSelected])

    useEffect(()=>{
        if(tipoSelected !== '' && denunciaSelected !== ''){
            NombreServMed(denunciaSelected,tipoSelected,setNombreServMed);
        }
    },[denunciaSelected,tipoSelected])

    useEffect(()=>{
        if(sistemaDenuSelected !== ''){
            EstablecimientoSalud(regionDenunciaSelected,sistemaDenuSelected,setEstablecimientoDenuncia);
        }
    },[regionDenunciaSelected,sistemaDenuSelected])
    //#endregion

    //#region Obtener Valores
    const getValue = (e) => {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        if (e.target.id === 'no_nacionalidad' && e.target.value === 'PERUANA') {
            newdata['no_pais'] = 'PERÚ';
        }
        setData(newdata);
    }
    
    const getValueFun = (e, state) => {
        state(e.target.value);
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
    }
    //#enregion
    //#endregion
    return (
        <>
            <div className="formContainer">
                <form className='form'>
                    <section id='datosPersona' className='formPersona'>
                        <div className='headerForm'>
                            <div className='columnHeaderForm'>
                                <h1>Datos de la persona</h1>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label>Nombre</label>
                                <input type="text" id='no_nombre' onChange={(e)=> getValue(e)}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'fapellido'>Apellido</label>
                                <input type="text" id="no_apellido" name="fapellido" onChange={(e)=> getValue(e)}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='ftiponombre' >Tipo de Nombre</label>
                                <select type="text" id="no_tipo_nombre" name="ftiponombre" onChange={(e)=> getValue(e)}>
                                    <option value="Sin información" hidden>--ELEGIR TIPO DE NOMBRE--</option>
                                    <option value={"INICIALES"}>INICIALES</option>
                                    <option value={"SEUDONIMO"}>SEUDONIMO</option>
                                    <option value={"ANÓNIMO"}>ANONIMO</option>
                                </select>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='ffecha'>Fecha</label>
                                <input type="date" id="fe_fecha" onChange={(e)=> getValueFun(e, setFechaSelected)}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'ftelefono'>Telefono</label>
                                <input type="text" id="no_telefono" onChange={(e)=> getValue(e)}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fnacionalidad'>Nacionalidad</label>
                                <select type="text" id="no_nacionalidad" name="fnacionalidad" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR NACIONALIDAD--</option>
                                    <option value={"PERUANA"}>PERUANA</option>
                                    <option value={"EXTRANJERA"}>EXTRANJERA</option>
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='fpais'>País</label>
                                <select type="text" id="no_pais" value={data.no_pais} onChange={(e) => getValue(e)}>
                                    <option value="" hidden>--ELEGIR PAIS--</option>
                                    {paises.map((pais, i) => (
                                        <option key={i} value={pais} selected={data.no_pais === pais}>{pais}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'femail'>E-mail</label>
                                <input type="text" id="no_correo" onChange={(e)=> getValue(e)}/>
                            </div>
                            <div className='column'>
                                <label htmlFor='fgenero'>Genero</label>
                                <select type="text" id="no_genero" onChange={(e)=> getValue(e)}>
                                    <option value="Sin información" hidden>--ELEGIR GENERO--</option>
                                    {genero.map((genero,i) => (
                                        <option key={i} value={genero} >{genero}</option>
                                    ))}
                                    <option value={"Sin información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='fregion'>Región</label>
                                <select type="text" id="no_region" onChange={(e)=> getValueFun(e,setRegionPersonaSelected)}>
                                    <option value="" hidden>--ELEGIR REGION--</option>
                                    {region.map((region,i) => (
                                            <option key={i} value={region} >{region}</option>
                                        ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'fsistema'>Sistema</label>
                                <select type="text" id="no_sistema" onChange={(e)=> getValueFun(e,setSistemaPersonaSelected)}>
                                    <option value="" hidden>--ELEGIR SISTEMA--</option>
                                    {sistema.map((sistema,i) => (
                                            <option key={i} value={sistema}>{sistema}</option>
                                        ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor='fseguro'>Seguro</label>
                                <select type="text" id="no_tipo_seguro" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR SEGURO--</option>
                                    {seguro.map((seguro,i) => (
                                        <option key={i} value={seguro} >{seguro}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='festablecimiento'>Establecimiento</label>
                                <select type="text" id="no_establecimiento_persona" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR ESTAB.--</option>
                                    {establecimiento.map((establecimiento,i) => (
                                            <option key={i} value={establecimiento}>{establecimiento}</option>
                                        ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor='fedades'>Edad</label>
                                <select type="text" id="no_edades" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR EDAD--</option>
                                    {edad.map((edad,i) => (
                                            <option key={i} value={edad}>{edad}</option>
                                        ))}
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'fdocuemnto'>Numero de documento</label>
                                <input type="text" id="nu_documento" onChange={(e)=> getValue(e)}/>
                            </div>
                        </div>
                    </section>

                    <section id='datosDenuncia' className='formDenuncia'>
                        <div className='headerForm'>
                            <div className='columnHeaderForm'>
                                <h1>Datos de la denuncia</h1>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor= 'fdenuncia'>Denuncia por</label>
                                <select type="text" id="no_denuncia" onChange={(e)=> getValueFun(e,setDenunciaSelected)}>
                                    <option value="" hidden>--ELEGIR DENUNCIA--</option>
                                    {denuncia.map((denuncia,i) => (
                                        <option key={i} value={denuncia}>{denuncia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor='fmotivo'>Motivo de la denuncia</label>
                                <select type="text" id="no_motivo" onChange={(e)=> getValueFun(e,setMotivoSelected)}>
                                    <option value="" hidden>--ELEGIR MOTIVO--</option>
                                    {motivo.map((motivo,i) => (
                                        <option key={i} value={motivo}>{motivo}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor='ftipomed'>Tipo de Med/Serv.</label>
                                <select type="text" id="no_tipo" onChange={(e)=> getValueFun(e,setTipoSelected)}>
                                    <option value="" hidden>--ELEGIR TIPO--</option>
                                    {tipo.map((tipo,i) => (
                                        <option key={i} value={tipo}>{tipo}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                        </div>
                        <div className='formRow'> 
                            <div className='column'>
                                <label htmlFor= 'fnombremed'>Nombre de Med/Serv</label>
                                <select type="text" id="no_nombre_medserv" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR MED/SERV--</option>
                                    {nombreServMed.map((nombre,i) => (
                                        <option key={i} value={nombre}>{nombre}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor='fregion'>Region</label>
                                <select type="text" id="no_region_denuncia" onChange={(e)=> getValueFun(e,setRegionDenunciaSelected)}>
                                    <option value="" hidden>--ELEGIR REGION--</option>
                                    {region.map((region,i) => (
                                        <option key={i} value={region}>{region}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'fsistema'>Sistema</label>
                                <select type="text" id="no_sistema_denuncia" onChange={(e)=> getValueFun(e,setSistemaDenuSelected)}>
                                    <option value="" hidden>--ELEGIR SISTEMA--</option>
                                    {sistemaDenu.map((sistema,i) => (
                                        <option key={i} value={sistema}>{sistema}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='fdistrito'>Distrito</label>
                                <input type="text" id="no_distrito_denuncia" onChange={(e)=> getValue(e)}/>
                            </div>
                            <div className='column'>
                                <label htmlFor= 'festablecimientoDenun'>Establecimiento</label>
                                <select type="text" id="no_establecimiento_denuncia" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR ESTAB.--</option>
                                    {establecimientoDenuncia.map((establecimiento,i) => (
                                        <option key={i} value={establecimiento}>{establecimiento}</option>
                                    ))}
                                    <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                </select>
                            </div>
                            <div className='column'>
                                <label htmlFor='fmetodoingreso'>Metodo de ingreso</label>
                                <select type="text" id="no_metodo" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR INGRESO--</option>
                                    {ingreso.map((ingreso,i) => (
                                        <option key={i} value={ingreso}>{ingreso}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='formRow'>
                            <div className='column'>
                                <label htmlFor='festado'>Acción tomada</label>
                                <select type="text" id="no_estado" onChange={(e)=> getValue(e)}>
                                    <option value="" hidden>--ELEGIR ACCION--</option>
                                    {accion.map((accion,i) => (
                                        <option key={i} value={accion}>{accion}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='formRow'> 
                            <div className='column100'>
                                <label htmlFor='fcomentario'>Comentario</label>
                                <textarea id='no_comentario' onChange={(e)=> getValue(e)}/>   
                                </div>
                        </div>
                        <div className='formRow'> 
                            <div className='column100'>
                                <button name="bgrabar"  className="butonSave" type= 'button' onClick={sendData} >Grabar</button>   
                            </div>
                            <br/>
                            <div className='column100'>
                                <button name="bgrabar"  className="butonSave" type= 'button' onClick={reload} >Limpiar</button>   
                            </div>
                        </div> 
                    </section>
                    
                </form>
            </div>
            <AlertModal
            isOpen={modalOpen}
            title="Creado Correctamente"
            message=""
            onConfirm={handleCancel}
            onCancel= {null}
            /> 
        </>
    )
  }

  export default Formulario;