import React,{useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import './ActualizarDenuncia.css'
import { obtenerDenuncia } from '../../Api/Api';
import { useNavigate } from "react-router-dom";
import { TodosDatos, SistemaSalud, Denuncias, MotivoDenuncia, TipoServicio, NombreServMed,ActualizarDenunciaApi,EstablecimientoSalud, TodosDatosPersona} from '../../Api/Api';
import AlertModal from '../../components/Modal/AlertModal';

const ActualizarDenuncia = ({ perfilUsuario }) => {

    console.log(perfilUsuario);
    //#region Variables Locales
    //#region Variables Api's
    const param = useParams();
    const [denuncia, setDenuncia] = useState([])
    const [paises, setPaises] = useState([]);
    const [region, setRegion] = useState([]);
    const [sistema, setSistema] = useState([]);
    const [genero, setGenero] = useState([]);
    const [seguro, setSeguro] = useState([]);
    const [ingreso, setIngreso] = useState([]);
    const [accion, setAccion] = useState([]);
    const [edad, setEdad] = useState([])
    const [sistemaDenu, setSistemaDenu] = useState([]);
    const [establecimiento, setEstablecimiento] = useState([]);
    const [motivo, setMotivo] = useState([]);
    const [denunciaPor, setDenunciaPor] = useState([])
    const [tipo, setTipo] = useState([])
    const [nombreServMed, setNombreServMed] = useState([])
    const [establecimientoDenuncia, setEstablecimientoDenuncia] = useState([])
    //#endregion

    //#region Variables Selected
    const [regionPersonaSelected, setRegionPersonaSelected] = useState('');
    const [sistemaPersonaSelected, setSistemaPersonaSelected] = useState('');
    const [denunciaSelected, setDenunciaSelected] = useState('');
    const [motivoSelected, setMotivoSelected] = useState('');
    const [tipoSelected, setTipoSelected] = useState('');
    const [regionDenunciaSelected, setRegionDenunciaSelected] = useState('');
    const [sistemaDenunciaSelected, setSistemaDenunciaSelected] = useState('');

    //#endregion
    //#endregion 

    //#region UseEffect
    useEffect(() => {
        obtenerDenuncia(param.id,setDenuncia);
    },[])

    useEffect(()=>{
        TodosDatos(setPaises,setRegion);
        Denuncias(setDenunciaPor)
        TodosDatosPersona(setGenero,setSeguro,setIngreso,setAccion,setEdad)
    },[])

    useEffect(()=>{
        if(regionPersonaSelected !== ''){
            SistemaSalud(regionPersonaSelected,setSistema);
        }
    },[regionPersonaSelected])

    useEffect(()=>{
        if(sistemaPersonaSelected !== ''){
            EstablecimientoSalud(regionPersonaSelected,sistemaPersonaSelected,setEstablecimiento);
        }
    },[sistemaPersonaSelected])

    useEffect(()=>{
        if(denunciaSelected !== ''){
            MotivoDenuncia(denunciaSelected,setMotivo);
        }
    },[denunciaSelected])

    useEffect(()=>{
        if(motivoSelected !== '' && denunciaSelected !== ''){
            TipoServicio(denunciaSelected,motivoSelected,setTipo);
        }
    },[denunciaSelected,motivoSelected])

    useEffect(()=>{
        if(tipoSelected !== '' && denunciaSelected !== ''){
            NombreServMed(denunciaSelected,tipoSelected,setNombreServMed);
        }
    },[denunciaSelected,tipoSelected])

    useEffect(()=>{
        if(regionDenunciaSelected !== ''){
            SistemaSalud(regionDenunciaSelected,setSistemaDenu);
        }
    },[regionDenunciaSelected])

    useEffect(()=>{
        if(sistemaDenunciaSelected !== ''){
            EstablecimientoSalud(regionDenunciaSelected,sistemaDenunciaSelected,setEstablecimientoDenuncia);
        }
    },[sistemaDenunciaSelected])

    // TodosDatosPersona(setGenero,setSeguro,setIngreso,setAccion)
    //#endregion

    //#region Traductor estado
    const getEstadoDenun = (estado) =>{
        if (estado === 1){
            return 'Resuelto'
        }
        else{
            return 'En proceso'
        }
    }

    const getEstadoMort = (estado) =>{
        if (estado === 1){
            return 'Fallecido'
        }
        else{
            return 'Vive'
        }
    }
    //#endregion

    //#region Obteniendo valores

    const[data,setData] = useState({
        id : param.id, 
        no_nombre: '',
        no_apellido:'' ,
        no_tipo_nombre: '',
        fe_fecha: '1900-01-01',
        no_nacionalidad: '',
        no_genero: '',
        no_pais: '',
        no_telefono: '',
        no_correo:'' ,
        no_region: '',
        no_sistema: '',
        no_tipo_seguro: '',
        no_establecimiento_persona: '',
        no_denuncia: '',
        no_motivo: '',
        no_tipo: '',
        no_nombre_medserv: '',
        no_region_denuncia: '',
        no_sistema_denuncia:'' ,
        no_distrito_denuncia: '',
        no_establecimiento_denuncia: '',
        no_metodo: '',
        no_estado: '',
        nu_resuelto: '-1',
        nu_mortalidad: '-1',
        no_comentario: ''
    });

    const getValue = (e)=>{
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const getValueFun = (e, state)=>{
        state(e.target.value);
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    //#endregion

    //#region sendData
    const sendData = ()=>{
        ActualizarDenunciaApi(data)
        alert('Se Actualizo correctamente')
    }
    //#endregion

    //#region Enrutadores
    const idSig = denuncia.nu_siguiente_id;
    const idAnt = denuncia.nu_anterior_id;
    const idUltimo = denuncia.nu_ultimo_id;

    let navigate = useNavigate(); 
    const routeChangeVerDenunciaAnterior = (id) =>{ 
        let path = `/ActualizarDenuncia/${idAnt}`; 
        navigate(path);
        window.location.reload();
    }

    const routeChangeVerDenunciaSiguiente = (id) =>{ 
        let path = `/ActualizarDenuncia/${idSig}`; 
        navigate(path);
        window.location.reload();
    }
    //#endregion

    return (
        <>
            <div className="formContainer">
                    <form className='form'>
                        <section id='datosPersona' className='formPersona'>
                            <div className='headerForm'>
                                <div className='columnHeaderFormL'>
                                    <h1 onClick={routeChangeVerDenunciaAnterior}>⇐</h1>
                                </div>
                                <div className='columnHeaderFormC'>
                                    <h1>Datos de la persona</h1>
                                </div>
                                {
                                    parseInt(param.id,10) === idUltimo? <div className='columnHeaderFormL'></div>:
                                    <div className='columnHeaderFormL'>
                                        <h1 onClick={routeChangeVerDenunciaSiguiente}>⇒</h1>
                                    </div>
                                }
                            </div>
                            <div className='formRow'>
                                <div className='column'>
                                    <label htmlFor='fname'>Nombre</label>
                                    <input type="text" id='no_nombre' defaultValue={denuncia.no_nombre || ''} onChange={(e)=> getValue(e)}/>
                                </div>
                                <div className='column'>
                                    <label htmlFor= 'fapellido'>Apellido</label>
                                    <input type="text" id="no_apellido" defaultValue={denuncia.no_apellido || ''}onChange={(e)=> getValue(e)}/>
                                </div>
                                <div className='column'>
                                    <label htmlFor='ftiponombre'>Tipo de Nombre</label>
                                    <select  id="no_tipo_nombre" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.no_tipo_nombre} hidden>{denuncia.no_tipo_nombre}</option>
                                        <option value="Iniciales">Iniciales</option>
                                        <option value="Seudonimo">Seudonimo</option>
                                        <option value="Anonimo">Anonimo</option>
                                        <option value="Sin_informacion">Sin informacion</option>
                                    </select>
                                </div>
                            </div>
                            <div className='formRow'>
                                <div className='column'>
                                    <label htmlFor='ffecha'>Fecha</label>
                                    <input type="date" id="fe_fecha" defaultValue={denuncia.fe_fecha} onChange={(e)=> getValue(e)}/>
                                </div>
                                <div className='column'>
                                    <label htmlFor= 'ftelefono'>Telefono</label>
                                    <input type="text" id="no_telefono" defaultValue={denuncia.no_telefono || ''} onChange={(e)=> getValue(e)}></input>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fnacionalidad'>Nacionalidad</label>
                                    <select type="text" id="no_nacionalidad" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.no_nacionalidad} hidden>{denuncia.no_nacionalidad}</option>
                                        <option value={"PERUANA"}>PERUANA</option>
                                        <option value={"EXTRANJERA"}>EXTRANJERA</option>
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                            </div>
                            <div className='formRow'>
                                <div className='column'>
                                    <label htmlFor='fpais'>País</label>
                                    <select type="text" id="no_pais" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.no_pais} hidden>{denuncia.no_pais}</option>
                                        {paises.map((pais,i) => (
                                            <option key={i} value={pais} >{pais}</option>
                                        ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor= 'femail'>E-mail</label>
                                    <input type="text" id="no_correo" defaultValue={denuncia.no_correo || ''} onChange={(e)=> getValue(e)}/>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fgenero'>Genero</label>
                                    <select type="text" id="no_genero" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.no_genero} hidden>{denuncia.no_genero}</option>
                                        {genero.map((genero,i) => (
                                            <option key={i} value={genero} >{genero}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='formRow'>
                                <div className='column'>
                                    <label htmlFor='fregion'>Región</label>
                                    <select type="text" id="no_region" onChange={(e)=> getValueFun(e,setRegionPersonaSelected)}>
                                        <option value={denuncia.no_region} hidden>{denuncia.no_region}</option>
                                        {region.map((region,i) => (
                                                <option key={i} value={region} >{region}</option>
                                            ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>

                                </div>
                                <div className='column'>
                                    <label htmlFor= 'fsistema'>Sistema</label>
                                    <select type="text" id="no_sistema" onChange={(e)=> getValueFun(e,setSistemaPersonaSelected)}>
                                        {regionPersonaSelected === ''? 
                                            <option value={denuncia.no_sistema} hidden>{denuncia.no_sistema}</option>:
                                            <option value={''} hidden >--SELECCIONA SISTEMA--</option>}
                                        {sistema.map((sistema,i) => (
                                            <option key={i} value={sistema}>{sistema}</option>
                                        ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fseguro'>Seguro</label>
                                    <select type="text" id="no_tipo_seguro" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.no_tipo_seguro} hidden>{denuncia.no_tipo_seguro}</option>
                                        {seguro.map((seguro,i) => (
                                            <option key={i} value={seguro} >{seguro}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='formRow'>
                                <div className='column'>
                                    <label htmlFor='festablecimiento'>Establecimiento</label>
                                    <select type="text" id="no_establecimiento_persona"  onChange={(e)=> getValue(e)}>
                                        {regionPersonaSelected === ''? 
                                            <option value={denuncia.no_establecimiento_persona} hidden>{denuncia.no_establecimiento_persona}</option>:
                                            <option value={''} hidden >--SELECCIONA ESTABLECIMIENTO--</option>}
                                            {establecimiento.map((establecimiento,i) => (
                                                <option key={i} value={establecimiento}>{establecimiento}</option>
                                            ))}
                                            <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fedades'>Edad</label>
                                    <select type="text" id="no_edades"  onChange={(e)=> getValue(e)}>
                                            <option value={denuncia.no_edades} hidden>{denuncia.no_edades}</option>:
                                            {edad.map((edad,i) => (
                                            <option key={i} value={edad}>{edad}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor= 'fdocumento'>Numero de documento</label>
                                    <input type="text" id="nu_documento" defaultValue={denuncia.nu_documento || ''} onChange={(e)=> getValue(e)}></input>
                                </div>
                            </div>
                        </section>

                        <section id='datosDenuncia' className='formDenuncia'>
                            <div className='headerForm'>
                                <div className='columnHeaderForm'>
                                    <h1>Datos de la denuncia</h1>
                                </div>
                            </div>
                            <div className='formRow'>
                                <div className='column'>
                                    <label htmlFor= 'fdenuncia'>Denuncia por</label>
                                    <select type="text" id="no_denuncia" onChange={(e)=> getValueFun(e,setDenunciaSelected)}>
                                        <option value={denuncia.no_denuncia} hidden>{denuncia.no_denuncia}</option>
                                        {denunciaPor.map((denuncia,i) => (
                                            <option key={i} value={denuncia}>{denuncia}</option>
                                        ))}
                                        <option value="DISCRIMINACIÓN" hidden>DISCRIMINACIÓN</option>
                                        <option value="SALUD LABORAL Y SOCIAL" hidden>SALUD LABORAL Y SOCIAL</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fmotivo'>Motivo de la denuncia</label>
                                    <select type="text" id="no_motivo" onChange={(e)=> getValueFun(e,setMotivoSelected)}>
                                        {denunciaSelected === '' ? 
                                            <option value={denuncia.no_motivo} hidden >{denuncia.no_motivo}</option> :
                                            <option value={''} hidden >--SELECCIONA MOTIVO--</option>}
                                            {motivo.map((motivo,i) => (
                                                <option key={i} value={motivo}>{motivo}</option>
                                            ))}
                                            <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor='ftipomed'>Tipo de Med/Serv.</label>
                                    <select type="text" id="no_tipo" onChange={(e)=> getValueFun(e,setTipoSelected)}>
                                        {motivoSelected === '' ? 
                                            <option value={denuncia.no_tipo} hidden >{denuncia.no_tipo}</option>:
                                            <option value={''} hidden >--SELECCIONA TIPO MED./SERV.--</option>
                                        }
                                        {tipo.map((tipo,i) => (
                                            <option key={i} value={tipo}>{tipo}</option>
                                        ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                            </div>
                            <div className='formRow'> 
                                <div className='column'>
                                    <label htmlFor= 'fnombremed'>Nombre de Med/Serv</label>
                                    <select type="text" id="no_nombre_medserv" onChange={(e)=> getValue(e)}>
                                        {tipoSelected === '' ? 
                                            <option value={denuncia.no_nombre_medserv} hidden >{denuncia.no_nombre_medserv}</option>:
                                            <option value={''} hidden >--SELECCIONA NOBMRE MED./SERV.--</option>
                                        }
                                        {nombreServMed.map((nombreServMed,i) => (
                                            <option key={i} value={nombreServMed}>{nombreServMed}</option>
                                        ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fregion'>Region</label>
                                    <select type="text" id="no_region_denuncia" name="fregion" onChange={(e)=> getValueFun(e,setRegionDenunciaSelected)}>
                                        <option value={denuncia.no_region_denuncia} hidden>{denuncia.no_region_denuncia}</option>
                                        {region.map((region,i) => (
                                                <option key={i} value={region} >{region}</option>
                                        ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor= 'fsistema'>Sistema</label>
                                    <select type="text" id="no_sistema_denuncia" onChange={(e)=> getValueFun(e,setSistemaDenunciaSelected)}>
                                        <option value={denuncia.no_sistema_denuncia} hidden>{denuncia.no_sistema_denuncia}</option>
                                        {sistemaDenu.map((sistema,i) => (
                                                <option key={i} value={sistema}>{sistema}</option>
                                        ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                            </div>
                            <div className='formRow'>
                                <div className='column'>
                                    <label htmlFor='fdistrito'>Distrito</label>
                                    <input type="text" id="no_distrito_denuncia" defaultValue={denuncia.no_distrito_denuncia} onChange={(e)=> getValue(e)}/>
                                </div>
                                <div className='column'>
                                    <label htmlFor= 'festablecimientoDenun'>Establecimiento</label>
                                    <select type="text" id="no_establecimiento_denuncia" onChange={(e)=> getValue(e)}>
                                        {regionDenunciaSelected === ''? 
                                            <option value={denuncia.no_establecimiento_denuncia} hidden>{denuncia.no_establecimiento_denuncia}</option>:
                                            <option value={''} hidden >--SELECCIONA ESTABLECIMIENTO--</option>
                                        }
                                        {establecimientoDenuncia.map((establecimiento,i) => (
                                                <option key={i} value={establecimiento}>{establecimiento}</option>
                                        ))}
                                        <option value={"Sin Información"}>SIN INFORMACIÓN</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fmetodoingreso'>Metodo de ingreso</label>
                                    <select type="text" id="no_metodo" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.no_metodo} hidden>{denuncia.no_metodo}</option>
                                        {ingreso.map((ingreso,i) => (
                                            <option key={i} value={ingreso} >{ingreso}</option>
                                        ))}
                                    </select>
                                </div>
                                
                            </div>
                            <div className='formRow'> 
                                <div className='column'>
                                    <label htmlFor='festado'>Acción tomada</label>
                                    <select type="text" id="no_estado" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.no_estado} hidden>{denuncia.no_estado}</option>
                                        {accion.map((accion,i) => (
                                            <option key={i} value={accion} >{accion}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor= 'festablecimientoDenun'>Estado de la Denuncia</label>
                                    <select type="text" id="nu_resuelto" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.nu_resuelto} hidden>{getEstadoDenun(denuncia.nu_resuelto)}</option>
                                        <option value={"0"}>En proceso</option>
                                        <option value={"1"}>Resuelto</option>
                                    </select>
                                </div>
                                <div className='column'>
                                    <label htmlFor='fmortalidad'>Mortalidad </label>
                                    <select type="text" id="nu_mortalidad" onChange={(e)=> getValue(e)}>
                                        <option value={denuncia.nu_mortalidad} hidden>{getEstadoMort(denuncia.nu_mortalidad)}</option>
                                        <option value={"0"}>Vive</option>
                                        <option value={"1"}>Fallecido</option>
                                    </select>
                                </div>
                            </div>
                            <div className='formRow'> 
                                <div className='column100'>
                                    <label htmlFor='fcomentario'>Comentario</label>
                                    <textarea id="no_comentario" defaultValue={denuncia.no_comentario} onChange={(e)=> getValue(e)}/>  
                                    </div>
                            </div>
                            <div className='formRow'> 
                                <div className='column100'>
                                    <button type='button' onClick={sendData}>Actualizar</button>   
                                    </div>
                            </div> 
                        </section>
                        
                    </form>
                </div>
        </>
    )
  }

  export default ActualizarDenuncia;