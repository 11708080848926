import React from "react";
import "../../../src/App.css"
import GraphBarFlujos from "../Consultas/graphs/graphBarFlujo"


function Home(){
    console.log('Rendering Home component'); 
    return(
    <>
        <div className='formRowBar'>
                <GraphBarFlujos/>
        </div>
    </>
    );
};

export default Home;