import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/NavBar/Navbar';
import Home from './pages/Home/Home';
import Formulario from './pages/Formulario/Formulario';
import Bandeja from './pages/Bandeja/Bandeja';
import Denuncia from './pages/Denuncia/Denuncia';
import ActualizarDenuncia from './pages/ActualizarDenuncia/ActualizarDenuncia';
import Consultas from './pages/Consultas/Consultas';
import Login from './pages/Login/Login';

function App() {
  // Estado para manejar la autenticación del usuario, inicializado desde localStorage
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    return loggedInStatus === 'true';
  });

  const [perfilUsuario, setPerfilUsuario] = useState(null);

  // Efecto para comprobar si hay una sesión activa en localStorage al cargar la aplicación
  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    const storedProfile = localStorage.getItem('perfil_usuario');
    if (loggedInStatus === 'true' && storedProfile) {
      setIsLoggedIn(true);
      setPerfilUsuario(JSON.parse(storedProfile));
    }
  }, []);

  // Función para manejar el inicio de sesión
  const handleLogin = (perfil) => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('perfil_usuario', JSON.stringify(perfil));
    setIsLoggedIn(true);
    setPerfilUsuario(perfil);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('perfil_usuario');
    setIsLoggedIn(false);
    setPerfilUsuario(null);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/consultas" element={<Consultas />} />

        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <PrivateRoute onLogout={handleLogout} perfilUsuario={perfilUsuario} setPerfilUsuario={setPerfilUsuario} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

function PrivateRoute({ onLogout, perfilUsuario, setPerfilUsuario }) {
  return (
    <>
      <Navbar onLogout={onLogout} perfilUsuario={perfilUsuario} setPerfilUsuario={setPerfilUsuario} />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/formulario" element={<Formulario />} />
        <Route path="/bandeja" element={<Bandeja perfilUsuario={perfilUsuario} />} />
        <Route path="/denuncia/:id" element={<Denuncia perfilUsuario={perfilUsuario}/>} />
        <Route path="/ActualizarDenuncia/:id" element={<ActualizarDenuncia perfilUsuario={perfilUsuario} />} />
      </Routes>
    </>
  );
}

export default App;
