import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DenunciasAnio } from "../../../Api/Api";
import '../Consultas.css';

const GraphLineDenuncias = ({ perfilUsuario }) => {
    const [dataChart, setDataChart] = useState([]);
    const [anio, setAnio] = useState(new Date().getFullYear().toString());

    useEffect(() => {
        DenunciasAnio(setDataChart, anio);
    }, [anio]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
        },
    };

    const labels = dataChart.map(x => x.no_nombre);

    const data = {
        labels,
        datasets: [
            {
                label: 'Denuncias',
                data: dataChart.map(x => x.denuncias),
                backgroundColor: 'rgba(62,64,149,255)',
                borderColor: 'rgba(237,47,89,255)'
            },
        ],
    };

    return (
        <div className="regionesDenunciasContainer">
            <h1 className="">Denuncias por Año</h1>
            <center>
                <div className="buttonGraph">
                    <label>Año</label>
                    <input
                        className='inputYear'
                        type='number'
                        min={"1900"}
                        max={"2099"}
                        value={anio}
                        onChange={(e) => setAnio(e.target.value)}
                        disabled={perfilUsuario === 0} // Bloquea el input si perfilUsuario es 0
                    />
                </div>
            </center>
            <div className="lineContainer">
                <Line options={options} data={data} />
            </div>
        </div>
    );
}

export default GraphLineDenuncias;
