import React from 'react';
import Modal from 'react-modal';
import './AlertModal.css'

Modal.setAppElement('#root')

const AlertModal = ({ isOpen, title, message, onConfirm, onCancel }) => {
  return (
    <Modal 
    isOpen={isOpen}
    style={{
      content: {
        width: '400px',
        height: '180px',
        top: "40%",
        left: "37%"
      }
    }}>
      <div className="custom-modal-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <button className="buttonModal" onClick={onConfirm}>Confirmar</button>
        {onCancel == null ? <></>: <button className="buttonModal" onClick={onCancel}>Cancelar</button>}
      </div>
    </Modal>
  );
};

export default AlertModal;